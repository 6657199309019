import { isLocal } from '../helpers'

export const SITE_URL = 'https://mirecipes.ru/'
// export const API_URL = isLocal()
//   ? 'http://localhost:8888/mg-ebook/api/'
//   : 'https://dash.mirecipes.ru/api/'
export const API_URL = 'https://dash.mirecipes.ru/api/'
export const PHOTOS_URL = 'https://dash.mirecipes.ru/photos/'
export const VERSION = '1.5.3'
export const API_REFRESH = 15 * 1000 // 15 секунд
export const SUBSCRIPTION_ENABLED = true
export const RECIPES_COUNT = 334

// Framer motions
export const motionVariantsRecipeCard = {
  initial: {
    scale: 0.9,
    opacity: 0,
  },
  in: {
    scale: 1,
    opacity: 1,
  },
  out: {
    scale: 1.2,
    opacity: 0,
  },
}

export const motionVariantsSingleRecipe = {
  initial: {
    scale: 0.98,
  },
  in: {
    scale: 1,
  },
  out: {
    scale: 1.2,
  },
}

export const motionVariantsList = {
  initial: {
    scale: 0.95,
  },
  in: {
    scale: 1,
  },
  out: {
    scale: 1.2,
  },
}

export const motionTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
}
