import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
  IoNewspaperOutline,
  IoHeartOutline,
  IoStorefrontOutline,
  IoCalendarOutline,
  IoImagesOutline,
  IoAppsOutline,
  IoHelpCircleOutline,
  IoBasketOutline,
  IoGiftOutline,
} from 'react-icons/io5'

const TabBar = () => {
  const user = useSelector((state) => state.auth.user)
  const [showMore, setShowMore] = useState(false)

  const showMoreHandler = () => {
    document.body.classList.toggle('submenu-open')
    setShowMore(!showMore)
  }

  return (
    <div className="tab-bar">
      <div className="tab-bar-item">
        <NavLink
          to="/recipes"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoNewspaperOutline />
          Рецепты
        </NavLink>
      </div>
      <div className="tab-bar-item">
        <NavLink
          to="/ration"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoCalendarOutline />
          Рацион
        </NavLink>
      </div>
      <div className="tab-bar-item">
        {user.shoplist_count > 0 && (
          <div className="tab-bar-badge">{user.shoplist_count}</div>
        )}
        <NavLink
          to="/shoplist"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoStorefrontOutline />
          Шоплист
        </NavLink>
      </div>
      <div className="tab-bar-item">
        {user.favourites_count > 0 && (
          <div className="tab-bar-badge">{user.favourites_count}</div>
        )}
        <NavLink
          to="/favorites"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoHeartOutline />
          Избранное
        </NavLink>
      </div>
      <div className="tab-bar-item tab-bar-item-parent">
        <a onClick={showMoreHandler}>
          <IoAppsOutline />
          {showMore ? 'Скрыть...' : 'Еще...'}
        </a>
        {showMore && (
          <div className="tab-bar-item-submenu">
            <div className="tab-bar-item">
              <NavLink
                to="/products"
                className={(navData) => {
                  return navData.isActive ? 'active' : ''
                }}
              >
                <IoBasketOutline />
                Продукты
              </NavLink>
            </div>
            <div className="tab-bar-item invite">
              <NavLink
                to="/invite"
                className={(navData) => {
                  return navData.isActive ? 'active' : ''
                }}
              >
                <IoGiftOutline />
                Подарок
              </NavLink>
            </div>
            <div className="tab-bar-item">
              <NavLink
                to="/blog"
                className={(navData) => {
                  return navData.isActive ? 'active' : ''
                }}
              >
                <IoImagesOutline />
                Блог
              </NavLink>
            </div>
            <div className="tab-bar-item">
              <a
                href="https://mirecipes.ru/guide/"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <IoHelpCircleOutline />
                Справка
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TabBar
